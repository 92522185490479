import React, { useState, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { storage } from "../../../firebase";
import { CustomTabs } from "../../../components/CustomTabs";
import {
  DefaultTextField,
  RichTextField,
  DateTextField,
} from "../../../components/CustomTextField";
const formatSelect = [
  "Celebrity Appearance",
  "Exhibition",
  "Meeting",
  "Networking Event",
  "Retreat",
  "Clinics",
  "Rally",
  "League",
  "Conference",
  "Vendor Presentations",
  "Other",
];
const DirectorEventInfo = ({ league: event, zoneList }) => {
  const [pageNum, setPageNum] = useState(0);
  const [leagueName, setLeagueName] = useState(event.name);
  const [leagueDescription, setLeagueDescription] = useState(event.description);
  const [leagueDescriptionSaved, setLeagueDescriptionSaved] = useState(
    event.description
  );
  const [social, setSocial] = useState(event.social);
  const [tag, setTag] = useState(event.tag);
  const [endDate, setEndDate] = useState(event?.endDate?.toDate());
  const [endDateDisplay, setEndDateDisplay] = useState(
    moment(event?.endDate?.toDate()).format("MM/DD/YYYY")
  );
  const [imageIcon, setImageIcon] = useState(event.imageURL);
  const [coverImage, setCoverImage] = useState(event.coverImageURL);
  const [format, setFormat] = useState(event.format);
  const [policy, setPolicy] = useState(event.policy);
  const [disclosure, setDisclosure] = useState(event.disclosure);
  const [policySaved, setPolicySaved] = useState(event.policy);
  const [disclosureSaved, setDisclosureSaved] = useState(event.disclosure);

  if(!event.name){
    return <Redirect to={{ pathname: "/Leagues" }} />;
  }
  let leagueAreas = [];
  event.locations.forEach((areaID) => {
    leagueAreas.push(zoneList.find((zone) => zone.areaID === areaID).area);
  });
  const pageLabels = ["Information", "Event", "Registration", "Event Cost"];
  const updateLeague = async (e) => {
    e.preventDefault();
    await event.ref.update({
      name: leagueName,
      description: leagueDescription,
      format: format,
      endDate: endDate,
      tag: tag,
      social: social,
      policy: policy,
      disclosure: disclosure,
    });
    setLeagueDescriptionSaved(leagueDescription);
  };
  return (
    <div className="column filler">
      <CustomTabs labels={pageLabels} value={pageNum} setValue={setPageNum} />
      <form className="column filler" onSubmit={updateLeague}>
        <Grid container spacing={6}>
          {pageNum === 0 && (
            <Fragment>
              <Grid xs={12} md={4} lg={4} xl={4} item>
                <div className="columnAlignCenter">
                  <Typography className="profileBoldFieldText">
                    Event Photo
                  </Typography>
                  <Avatar
                    className="image200"
                    variant="rounded"
                    src={imageIcon}
                  />
                  <input
                    type="file"
                    hidden
                    id="imageUpload"
                    onChange={async (e) => {
                      if (e.target.files[0] && e.target.files)
                        setImageIcon(URL.createObjectURL(e.target.files[0]));
                      let getURL = "";
                      await storage
                        .ref(`leagueDocuments/${event.id}/leaguePhoto`)
                        .put(e.target.files[0]);
                      await storage
                        .ref()
                        .child(`leagueDocuments/${event.id}/leaguePhoto`)
                        .getDownloadURL()
                        .then((url) => {
                          getURL = url;
                        });
                      await event.ref.update({ imageURL: getURL });
                    }}
                  />
                  <label className="columnCenter" htmlFor="imageUpload">
                    <Button component="span" variant="text">
                      <Typography className="profileHighlightFieldText">
                        Change Event Image
                      </Typography>
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid xs={12} md={8} lg={8} xl={8} item>
                <div className="columnCenter">
                  <Typography className="profileBoldFieldText">
                    Event Banner
                  </Typography>
                  <img
                    className="directorLeagueCoverImage"
                    alt="alt"
                    src={coverImage}
                  />
                  <input
                    type="file"
                    hidden
                    id="coverUpload"
                    onChange={async (e) => {
                      if (e.target.files[0] && e.target.files)
                        setCoverImage(URL.createObjectURL(e.target.files[0]));
                      let getURL = "";
                      await storage
                        .ref(`leagueDocuments/${event.id}/coverImage`)
                        .put(e.target.files[0]);
                      await storage
                        .ref()
                        .child(`leagueDocuments/${event.id}/coverImage`)
                        .getDownloadURL()
                        .then((url) => {
                          getURL = url;
                        });
                      await event.ref.update({ coverImageURL: getURL });
                    }}
                  />
                  <label className="columnCenter" htmlFor="coverUpload">
                    <Button component="span" variant="text">
                      <Typography className="profileHighlightFieldText">
                        Change Event Banner
                      </Typography>
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid xs={12} item>
                <Typography className="createLeagueFieldText">
                  Event Name
                </Typography>
                <DefaultTextField
                  required={true}
                  state={leagueName}
                  setState={setLeagueName}
                  placeholder="Name"
                />
                <Typography className="createLeagueFieldText margin20Top">
                  Event Description
                </Typography>
                <RichTextField
                  value={leagueDescription}
                  setValue={setLeagueDescription}
                  defaultValue={leagueDescriptionSaved}
                  placeholder="Describe your Event..."
                />
                <Typography className="createLeagueFieldText margin20Top">
                  End Date
                </Typography>
                <DateTextField
                  dateString={endDateDisplay}
                  setDateString={setEndDateDisplay}
                  date={endDate}
                  setDate={setEndDate}
                />
              </Grid>
            </Fragment>
          )}
          {pageNum === 1 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography className="createLeagueFieldText">
                  Event Type
                </Typography>
                <TextField
                  select
                  required
                  value={format}
                  onChange={(e) => {
                    setFormat(e.target.value);
                  }}
                >
                  {formatSelect.map((s, i) => (
                    <MenuItem key={i} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography className="createLeagueFieldText">
                  Event Tags
                </Typography>
                <DefaultTextField
                  required={true}
                  state={tag}
                  setState={setTag}
                  placeholder="tags"
                />
                <Typography className="createLeagueFieldText">
                  Event Social Media
                </Typography>
                <DefaultTextField
                  required={true}
                  state={social}
                  setState={setSocial}
                  placeholder="social media"
                />
              </Grid>
              <Grid xs={12} item>
                <Typography className="createLeagueFieldText margin20Top">
                  Refund Policy
                </Typography>
                <RichTextField
                  required
                  setValue={setPolicy}
                  defaultValue={policySaved}
                  setValueSaved={setPolicySaved}
                  placeholder="Describe your Refund Policies..."
                />
                <Typography className="createLeagueFieldText margin20Top">
                  Legal Disclosures and other Rules and Regulation (optional)
                </Typography>
                <RichTextField
                  setValue={setDisclosure}
                  defaultValue={disclosureSaved}
                  setValueSaved={setDisclosureSaved}
                  placeholder="Describe your Legal Disclosures and other Rules and Regulation..."
                />
              </Grid>
            </Fragment>
          )}
          {pageNum === 2 && (
            <Fragment>
              <Grid xs={12} item>
                {event.status === "pending" ? (
                  <Typography variant="h5">
                    Registration Start Date: Pending Approval
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    Registration Start Date:{" "}
                    {moment(event?.approvalDate?.toDate()).format("M/D/YYYY")}
                  </Typography>
                )}
                <Typography variant="h5">
                  Registration Ends {event.registrationEndDate}
                </Typography>
                <Typography variant="h5">
                  This event can handle {event.numPlayerRange[0]} to{" "}
                  {event.numPlayerRange[1]} players
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Typography variant="h5">Availiable Areas</Typography>
                <div className="padding10">
                  {leagueAreas.map((area, index) => (
                    <Typography key={index} variant="subtitle1">
                      {area}
                    </Typography>
                  ))}
                </div>
              </Grid>
            </Fragment>
          )}
          {pageNum === 3 && (
            <Fragment>
              <Grid xs={12} item>
                <Typography variant="h5">
                  Price to join: ${event.price}
                </Typography>
                <Typography variant="h5">
                  You get a {event.percentCut}% cut of earnings
                </Typography>
              </Grid>
            </Fragment>
          )}
        </Grid>
        <div className="filler" />
        <Divider className="margin20Vertical" />
        <div className="rowCenter">
          <div className="filler" />
          <Button
            className="profileSaveButton"
            variant="outlined"
            type="submit"
          >
            <Typography className="profileSaveButtonText">Save</Typography>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DirectorEventInfo;
